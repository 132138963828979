#search-results-wrapper {
	text-align: left;
}

#search-content {
	max-width: 600px;
}

div:has(#search-content) {
	text-align: center;
}