$red: rgba(188, 68, 48, 1);
$teal: #30aba3;
$skyBlue: #3c9dd7;
$lightBlue: #007DF9;
$darkBlue: #0036B0;
$darkGray: #616264;
$midGray: #333333;
$blackPearl: #1F262D;
$lightGray: #e3e5eb;
$navy: #384455;
$pink: #D22486;
$purple: #A900CD;
$bgDarkGrey: #626264;
$gradient: linear-gradient(45deg, $lightBlue 0%, $darkBlue);
$smallGradient: linear-gradient(45deg, $lightBlue 0%, $darkBlue);
$blueGradient: linear-gradient(45deg, $lightBlue 0%, $darkBlue);