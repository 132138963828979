// scss-docs-start import-stack
// Configuration
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";   
@import "../../../node_modules/bootstrap/scss/spinners";


// Helpers
@import "../../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

@import "../libs/slick";
@import "../libs/slick-theme";
@import "../elements/search-bar";
@import "../elements/variables";
@import "../elements/overrides";
@import "../elements/mega-menu";
@import "../elements/footer";

/* Home Module */
#home-hero {
    transition: background .5s linear;
}

#cards-panel {
    .card-content {
        min-height: 300px;
    }
    .card-image {
        z-index: 0;
    }
    .card-text {
        z-index: 2;
        margin-top: -1rem;
    }
}

.arrow-icon {
    width: 25px;
    height: 19px;
    visibility: hidden;

    &.arrow-top {
        top:-8px;
    }

    &.arrow-bottom {
        bottom: -1px;
    }
}

.active .arrow-icon {
    display: block;
    position: absolute;
    visibility: visible;
}

.nav-item:nth-child(1) .arrow-icon svg,
.nav-item:nth-child(4) .arrow-icon svg {
    /* gradient and fallback color */
    fill: url(#arrow-gradient-edge);
    // position: absolute;
}

.nav-item:nth-child(2) .arrow-icon svg,
.nav-item:nth-child(3) .arrow-icon svg {
    /* gradient and fallback color */
    fill: url(#arrow-gradient-center);
    // position: absolute;
}


#home-products .tab-pane .col-md-6 {
	background-position: top right !important;
	background-repeat: no-repeat !important;
}




#home-products {
    .border-accent-products {

        border-image-slice: 1 0 1 0 !important;

        &:first-child {
            border-image: linear-gradient(to right, rgba(0,125,249,0),rgba(0,125,249,.6) 60%, rgba(0,125,249,1)) 1 ;
            border-right: unset;
        }
        &:nth-child(2) {
            border-image: linear-gradient(to right, rgba(0,125,249,1) 10%, rgb(0,54,176)) 1 ;
        }
        &:nth-child(3) {
            border-image: linear-gradient(to right, rgba(0,54,176,1) 10%, rgb(0,125,249)) 1 ;
        }
        &:last-child {
            border-image: linear-gradient(to right, rgba(0,125,249, 1), rgba(0,125,249, .6) 40%, rgba(0,125,249, 0)  ) 1 ;
        }
    }


    @media only screen and (max-width:992px) {
        .border-accent-products:nth-child(3) {
        border-image: linear-gradient(to right, rgba(0,54,176,0), rgb(0,125,249), rgb(0,54,176)) 1 ;
        }
        .border-accent-products:nth-child(2) {
        border-image: linear-gradient(to right, rgb(0,125,249), rgb(0,125,249), rgba(0,54,176,0)) 1 ;
        }
    }

    @media only screen and (max-width:575px) {
        .border-accent-products {
        border-image: linear-gradient(to right, rgba(0,54,176,0), rgb(60,125,249), rgba(0,54,176,0)) 1 !important;
        }
    }

    .nav-link:hover {
        color: #000000 !important;
    }

    .nav-link.active span{
        @extend .text-gradient;
    }

    a.product-logo {
        &:first-child {
            img {
                margin-left: -15px;
            }
        }
        img {
            height: 60px;
            opacity: .6;
            transition: opacity .2s linear;
            max-width: none !important;
        }

        &.active-product {
            img {
                &.img-regular {
                    filter: invert(28%) sepia(86%) saturate(2277%) hue-rotate(196deg) brightness(101%) contrast(103%);
                    opacity: 1;
                }
            }
        }

        &:hover {
            img {
                opacity: 1;
            }
        }
        &:hover::after {
            content: '';
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -3px;
            height: 3px;
            background: $smallGradient
        }

        &#product-mycastcrew:hover {
            fill: red;
        }
    }
}

#hero-arrows {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
}
.bounce-2 {
    animation-name: bounce-2;
    animation-timing-function: ease;
}
@keyframes bounce-2 {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-25px); }
    100% { transform: translateY(0); }
}
@include media-breakpoint-up(md) {
    .product-descriptions {
        min-height: 250px;
        height: 250px;
    }
}

.product-link.btn-secondary:hover {
    background: $lightBlue !important;
    transition: background 1s;
}

#carousel-bottom {
    .slick-list {
        a {
            &:hover {
                img {
                    transform: scale(1.05);
                    transition: transform .3s ease-in;
                }
                .label-bg {
                    background: $lightBlue !important;
                    color: #FFFFFF !important;
                }
            }
            img {
                transform: scale(1);
                transition: transform .3s ease-out;
            }
        }
    }
}

/* Products Module */
@media only screen and (max-width: 768px) {
    #side-module-with-background {
        background: none !important;
    }
    #main_nav .nav-link {
        background: #f8f9fa;
        text-shadow: none;
        color: #000000 !important;
    }
    #main_nav .nav-item {
        .dropdown-menu {
            width: 100% !important;
        }
    }
}
@media only screen and (min-width: 767px) {
    #side-module-with-background {
        background-position: -350px 20px !important;
    }
}
@media only screen and (min-width: 1024px) {
    #side-module-with-background {
        background-position: -310px 0 !important;
    }
}
@media only screen and (min-width: 1280px) {
    #side-module-with-background {
        background-position: -200px 0 !important;
    }
}
