footer a:hover::after,
a.gradient-underline:hover::after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 3px;
    background: $blueGradient
}

#legal-links {
    a {
        color: $lightBlue;
    }
}


#help-center-widget {
    >div {
        transform: scale(1);
        transition: transform .3s ease-out;
        &:hover {
            transform: scale(1.1);
            transition: transform .3s ease-in;
        }
    }
}