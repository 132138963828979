.rounded-4 {
  border-radius: .4rem !important;
}

.rounded-5 {
  border-radius: .5rem !important;
}

.rounded-xl {
  border-radius: 1rem !important;
}

.rounded-xxl {
  border-radius: 2.2rem !important;
}

.rounded-xxxl {
  border-radius: 4rem !important;
}

.text-primary {
  color: $lightBlue !important;
}

.text-sky-blue {
  color: $lightBlue !important;
}

.text-dark-grey {
  color:rgba(0, 0, 0, 0.75) !important
}

.text-light-grey {
  color: $lightGray !important;
}

.text-pearl {
  color: #1F262D !important;
}

.text-pink {
  color: $pink !important;
}

.text-purple {
  color: $purple !important;
}

.text-shadow {
  text-shadow: #000000 0 0 1rem;
}

.text-shadow-sm {
  text-shadow: #000000 0 0 2px;
}

.text-light-blue,
.text-light-blue h1,
.text-light-blue h2,
.post-template-default .section h2,
.post-template-default .section h3 {
  color: $lightBlue !important;
}

.post-template-default .section h3,
.post-template-default .section h2 {
  @extend .mt-5;
}

.text-dark-blue {
  color: $darkBlue !important;
}

.text-gradient,
.text-gradient > *,
.text-strong-gradient strong,
.fs-xxl strong,
.single-post article .inner h2:first-child {
  background: -webkit-linear-gradient(45deg, $blackPearl,  $darkGray);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;

}

.gradient-border {
  background: linear-gradient(90deg, #0000FF 0%, #A900CD 50%, #ED1C24 100%);
}

.bg-light {
  background-color: $lightGray !important;
}

.bg-dark-blue {
  background-color: $darkBlue !important;
}

.bg-sky-blue {
  background-color: $lightBlue !important;
}

.bg-black-pearl {
  background-color: $blackPearl;
}

.bg-dark-grey {
  background-color: $bgDarkGrey !important;
}

.bg-gradient-teal-blue {
  background: $smallGradient !important;
  h2 {
    p { 
      font-weight: 400 !important;
      strong {
        color: #FFFFFF !important;
      }
    }
  }
}

.bg-gradient-multi {
  background: $smallGradient !important;
}

.border-gradient {
  border-image: $smallGradient !important;
}

.border-sky-blue {
  border-color: $lightBlue !important;
}

.border-blue {
  border-color: $darkBlue !important;
}

.border-bottom-4 {
  border-bottom-width: 4px !important;
}

.border-top-4 {
  border-top-width: 4px !important;
}

.fs-5-5 {
  font-size: 1.1rem !important;
}

.fs-7 {
  font-size: .75rem !important;
}

.fs-8 {
  font-size: 1rem !important;
}

.fs-l {
  font-size: 2rem !important;
}

.fs-xl {
  font-size: 4rem !important;
}

.fs-xxl {
  font-size: 6rem !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-60 {
  width: 60% !important;
}

.w-100-px {
  width: 100px !important;
}

.vh-95 {
  height: 95vh!important;
}

.vh-25 {
  height: 25vh!important;
}

.vh-35{
  height: 35vh!important;
}

.mvh-300 {
  min-height: 300px!important;
}

.mvh-200 {
  min-height: 200px!important;
}

.opacity-90 {
  opacity: .90 !important;
}

body {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  color: rgba(0, 0, 0, 0.5) !important;
}

p, div {
  @extend .fs-5-5;
}

h1, h2, h3, h4, h5, h6 {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  color: $blackPearl;
}

.btn.btn-secondary {
  color: white !important;
}

.btn.btn-primary {
  color: white !important;
  background-color: $lightBlue !important;
  border: 3px solid $lightBlue !important;
}

.btn.btn-primary:hover {
  color: $lightBlue !important;
  background-color: #FFFFFF !important;
  border: 3px solid $lightBlue !important;
  text-decoration: underline;
}

.btn.btn-secondary-variant {
  background-color: $lightGray !important;
  color: $darkGray !important;
}

.btn.btn-secondary:hover {
  background: $lightBlue !important;
  transition: background .5s;
}

.btn.btn-secondary-variant:hover {
  color: $lightGray !important;
}

a {
  color: $lightBlue !important;
}

strong.forms-resources-subt {
  font-size: 1.3rem;
}

.bg-gradient-teal-blue p,
.bg-gradient-teal-blue *{
	color: #FFFFFF !important;
}

.bg-pink {
  background-color: $pink !important;
}

.bg-purple {
  background-color: $purple !important;
}

.header-gradient-pearl-black,
.bg-gradient-pearl-black {
  background: linear-gradient(45deg, #1F262D 0%, #000000 100%);
}

.header-gradient-yellow,
.bg-gradient-yellow {
  background: linear-gradient(0deg, #ffcf48 50%, #f5dc96 100%);
}

.header-gradient-blue-black,
.bg-gradient-blue-black {
  background: linear-gradient(45deg, #007DF9 0%, #0036B0 35%, #000000 100%);
}

.header-gradient-purple {
  background: linear-gradient(to right, #923bd1, #000000) !important;
}

.gradient-border {
  background: linear-gradient(90deg, #0000FF 0%, #A900CD 50%, #ED1C24 100%);
}