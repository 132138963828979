
//Menu gradients
$floatingMenuGradient20:  linear-gradient(45deg, rgba(31,38,45,0.2), rgba(0,0,0,0.2));
$floatingMenuGradient40:  linear-gradient(45deg, rgba(31,38,45,0.6), rgba(0,0,0,0.4));
$floatingMenuGradient60:  linear-gradient(45deg, rgba(31,38,45,0.6), rgba(0,0,0,0.6));
$floatingMenuGradient80:  linear-gradient(45deg, rgba(31,38,45,0.8), rgba(0,0,0,0.8));
$floatingMenuGradient100: linear-gradient(45deg, rgba(31,38,45,1), rgba(0,0,0,1));

//Menu gradients
$floatingMenuWhite20:  rgba(255,255,255,0.2);
$floatingMenuWhite40:  rgba(255,255,255,0.4);
$floatingMenuWhite60:  rgba(255,255,255,0.6);
$floatingMenuWhite80:  rgba(255,255,255,0.8);
$floatingMenuWhite100: #FFFFFF;

@media all and (min-width: 992px) {
    .navbar .has-megamenu {
        position: static!important;
    }
    .navbar .megamenu {
        left: auto;
    }
}

@media only screen and (max-width: 600px) {
    #my-cast-and-crew {
        font-size: .8rem;
    }
}

@media(max-width: 991px) {
    .navbar.fixed-top .navbar-collapse,
    .navbar.sticky-top .navbar-collapse {
        overflow-y: auto;
        max-height: 90vh;
        margin-top: 10px;
    }
    .navbar-collapse {
        background-color: #FFFFFF !important;
        .nav-item > .dropdown-menu {
            width: 100% !important;
        }
        .navbar-nav .nav-item .nav-link {
            color: $blackPearl !important;
            text-shadow: none;
        }
    }
}

.nav-container {
    &.nav-scrolled {
        animation: fadeIn .2s;
        -webkit-animation: fadeIn .2s;
        -moz-animation: fadeIn .2s;
        -o-animation: fadeIn .2s;
        -ms-animation: fadeIn .2s;

        &.nav-scrolled-20 {
            background: $floatingMenuGradient20;
        }
        &.nav-scrolled-40 {
            background: $floatingMenuGradient40;
        }
        &.nav-scrolled-60 {
            background: $floatingMenuGradient60;
        }
        &.nav-scrolled-80 {
            background: $floatingMenuGradient80;
        }
        &.nav-scrolled-100 {
            background: $floatingMenuGradient100;
        }
    }
    @keyframes fadeIn {
        0% {opacity:.7;}
        100% {opacity:1;}
      }
    .navbar-nav {
        .nav-link {
            &.show {
                background: white;
                color: $darkGray !important;
                border-radius: .2rem;
                text-shadow: none;
            }
            &:hover {
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
    }
}

.error404 {
    .navbar {
        &.fixed-top {
            @extend .bg-gradient-pearl-black;
            &.nav-scrolled {
                &,
                &.nav-scrolled-20,
                &.nav-scrolled-40,
                &.nav-scrolled-60,
                &.nav-scrolled-80 {
                    @extend .bg-gradient-pearl-black;
                }
            }
        }
    }
}

.nav-item .dropdown-menu {
    top: 54px;
    max-height: 100vh;
    overflow: scroll;
}

#my-cast-and-crew {
    max-width: 130px;
}

#my-cast-and-crew:hover {
    background: $lightBlue;
    color: #FFFFFF !important;
}

#menu-companies-list,
#menu-companies-link .collapse-list
 {
    display: none;
}

#menu-companies-link {
    cursor: pointer;
}

.navbar-brand img {
    max-width: 200px;
}

#search-results a:hover {
    text-decoration: underline !important;
}